import React from 'react';
import PropTypes from 'prop-types';
import errorImg from '../assets/images/exception.png';

class ErrorBoundary extends React.Component {

  static propTypes = {
    children: PropTypes.object

  }
  static defaultProps = {
    children: {}
  }
  constructor(props) {
    super(props);
    this.state = { error: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    console.error(error)
  }

  render() {
    const { error } = this.state
    const { children } = this.props
    if (error) {
      // render fallback UI
      return <img alt="errorImg" style={{ marginLeft: '30%' }} src={errorImg} />;
    }
    // when there's not an error, render children untouched
    return children;
  }
}
export default ErrorBoundary