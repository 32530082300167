import React from 'react';
import { Typography } from '@material-ui/core';
import { FOOTER_HEIGHT } from 'constants/common';
import styled from 'styled-components';
import { Image } from 'react-bootstrap';

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrapper>
                <FooterText>
                    <Typography style={{ width: '100%', textAlign: 'center' }}>
                        Powered by {" "}
                        <Image src={require('../assets/images/sxp-logo.png')} alt="SxP" width="40" />
                    </Typography>
                </FooterText>
            </FooterWrapper>
        </FooterContainer>
    );
};

const FooterContainer = styled.div`
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100% !important;
    height: ${FOOTER_HEIGHT}px !important ;
    background: #ffffff;
    color: #334155;
    display: flex;
    justify-content: center;
    z-index: 1300;
    box-shadow: 0 0 4px rgba(0,0,0,0.5);
`;

const FooterWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 100px;
`;

const FooterText = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    font-size: 12px;
`;

export default Footer;
