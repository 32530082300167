import React from 'react';

const AddTicketIcon = () => {
   return (
      <svg width="20px" height="14px" viewBox="0 0 20 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
         <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Outlined" transform="translate(-102.000000, -956.000000)">
               <g id="Av" transform="translate(100.000000, 852.000000)">
                  <g id="Outlined-/-AV-/-playlist_add" transform="translate(0.000000, 98.000000)">
                     <g>
                        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                        <path d="M14,10 L2,10 L2,12 L14,12 L14,10 Z M14,6 L2,6 L2,8 L14,8 L14,6 Z M18,14 L18,10 L16,10 L16,14 L12,14 L12,16 L16,16 L16,20 L18,20 L18,16 L22,16 L22,14 L18,14 Z M2,16 L10,16 L10,14 L2,14 L2,16 Z" id="🔹-Icon-Color" fill="#1D1D1D"></path>
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>
   )
}

export default AddTicketIcon
