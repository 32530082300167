import React from 'react';
import { Provider } from 'react-redux';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import Keycloak from 'keycloak-js';
import Navigator from './Navigator';
import { REACT_TOKEN, TOKEN, EMAIL, CURRENT_USER, USER_ROLES, AUTH_SUCCESS, AUTH_LOGOUT, TOKEN_EXPIRED } from './constants/common'
import Spinner from 'components/Spinner';
import configureStore from './store'
import LoadSxpChat from 'components/chatWidget';
import ErrorBoundary from 'components/errorBoundry';

export const store = configureStore();
function App() {

  const keycloak = new Keycloak({
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    url: process.env.REACT_APP_KEYCLOAK_URL,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  });

  const setTokens = async () => {
    const { token, refreshToken, idTokenParsed, loadUserInfo } = keycloak;

    if (token && refreshToken && idTokenParsed) {
      sessionStorage.setItem(REACT_TOKEN, token);
      localStorage.setItem(TOKEN, token);
      localStorage.setItem(CURRENT_USER, idTokenParsed.preferred_username); // For case inbox filter api
      localStorage.setItem(EMAIL, idTokenParsed.email);
    }
    const userInfo = await loadUserInfo()
    const roles = userInfo?.groups?.join('')?.split('/')?.filter((el) => el !== '')
    localStorage.setItem(USER_ROLES, roles);
  };

  const refreshAccessToken = () => {
    keycloak
      .updateToken(50)
      .success((refreshed) => {
        if (refreshed) {
          setTokens();
        }
      })
      .error(() => {
        sessionStorage.clear();
        keycloak.logout();
      });
  };

  const handleEvent = (event) => {
    if (event === AUTH_SUCCESS) setTokens();
    if (event === TOKEN_EXPIRED) refreshAccessToken();
    if (event === AUTH_LOGOUT) sessionStorage.clear();
  };

  return (
    <ErrorBoundary>
      <ReactKeycloakProvider
        initOptions={{
          onLoad: 'login-required',
          checkLoginIframe: false,
        }}
        authClient={keycloak}
        onEvent={handleEvent}>
        <Provider store={store}>
          <Spinner />
          <Navigator />
          <LoadSxpChat />
        </Provider>
      </ReactKeycloakProvider>
    </ErrorBoundary>
  );
}

export default App;
