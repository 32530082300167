import React, { useEffect, useState } from 'react';
import { Route, Router } from 'react-router-dom'
import { CASE_INBOX, VIEW_TICKET } from './constants/Routes';
import { MANAGER, ANALYST } from './constants/common';
import DashboardLayout from './layout/Layout';
import { useKeycloak } from '@react-keycloak/web';
import MicroFrontend from './components/MicroFrontEnd';
import LandingPage from './components/LandingPage';
import { ThemeProvider } from '@material-ui/core';
import { INITIAL_THEME } from './constants/common';
import { createBrowserHistory } from 'history';
import MenuForm from 'components/MenuForm';
import { Redirect } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';


const PrivateRoute = ({ roles, ...restProps }): React.ReactElement => {

    return (
        <Route
            {...restProps}
            render={(): React.ReactElement =>
                (roles?.includes(MANAGER) || roles?.includes(ANALYST))
                    ? (<Redirect to={{
                        pathname: CASE_INBOX,
                    }} />)
                    : (<DashboardLayout>
                        <LandingPage
                            primaryText="Welcome to Techsophy Ticketing System"
                            secondaryText="Navigate through the side menu" />
                    </DashboardLayout>)
            }
        />
    );
};

const ModelerRoute = ({ component: Component, roles, path, ...restProps }): React.ReactElement => {
    if (!(roles?.includes(MANAGER) || roles?.includes(ANALYST)) && path === CASE_INBOX) {
        return (<Redirect to={{ pathname: '/' }} />)
    }
    return (
        <Route
            path={path}
            {...restProps}
            render={(props): React.ReactElement => (
                <DashboardLayout>
                    <Component {...props} />
                </DashboardLayout>
            )}
        />
    );
};

const Navigator = (): React.ReactElement => {
    const [roles, setRoles] = useState(() => {
        const data = localStorage.getItem('user-roles')?.split(',')
        return data || []
    })
    const history = createBrowserHistory({
        basename: '/',
        // gave base name as model because playground case-inbox only has MFE_CONTAINER_BASENAME = /model/caseInbox
    });
    const commonHost = 'https://awgment-playground.techsophy.com';
    const caseInboxHost = `https://awgment-playground.techsophy.com/case-inbox`;
    const { keycloak, initialized } = useKeycloak();
    const token = sessionStorage.getItem('react-token');
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        setFlag(true);
    }, []);

    useEffect(() => {
        if (!roles?.length) {
            const data = localStorage.getItem('user-roles')?.split(',') || []
            if (data) setRoles(data)
        }
    }, [roles])

    if (initialized && keycloak.authenticated && token && flag && roles?.length) {
        return (
            <>
                <ThemeProvider theme={INITIAL_THEME}>
                    <StyledThemeProvider theme={INITIAL_THEME} >
                        <Router history={history} >
                            <PrivateRoute
                                exact
                                path={'/'}
                                roles={roles}
                            />
                            <ModelerRoute
                                exact
                                path={CASE_INBOX}
                                roles={roles}
                                component={(): React.ReactElement => (
                                    <MicroFrontend
                                        history={history}
                                        host={caseInboxHost}
                                        name="CaseInboxMFE"
                                        mainhost={commonHost}
                                    />
                                )}
                            />
                            <ModelerRoute
                                path={CASE_INBOX + '/*'}
                                roles={roles}
                                component={(): React.ReactElement => (
                                    <MicroFrontend
                                        history={history}
                                        host={caseInboxHost}
                                        name="CaseInboxMFE"
                                        mainhost={commonHost}
                                    />
                                )}
                            />
                            <ModelerRoute exact path={VIEW_TICKET} roles={roles} component={MenuForm} />

                        </Router>
                    </StyledThemeProvider>
                </ThemeProvider>
            </>
        );
    }
    return <div>Loading ... </div>;
};

export default Navigator;
