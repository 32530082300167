import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import NavItem from './NavBar/NavItem';
import styled, { withTheme } from 'styled-components';
import { FOOTER_HEIGHT, HEADER_HEIGHT, SIDEBAR_WIDTH } from 'constants/common';
import { getMenu } from 'services/commonServices';

interface Theme { }

export interface ISideBar {
    theme: Theme
}
const Sidebar: React.FC<ISideBar> = () => {
    const location = useLocation();
    const [edit, setEdit] = useState(null);
    const [formLinks, setFormLinks] = useState<any>([]);
    const [componentLinks, setComponentLinks] = useState<any>([]);

    useEffect(() => {
        if (edit) {
            setEdit(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const getData = async () => {
        const data = await getMenu();
        if (data?.length) {
            const formComponentsData = data.filter((obj) => obj.type === 'form');
            const genericComponentsData = data.filter((obj) => (obj.type === 'component' && obj.label === 'Case Inbox'));
            setComponentLinks(genericComponentsData);
            setFormLinks(formComponentsData);
        }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { getData() }, []);

    const content = (
        <DrawerWrapper variant="permanent" anchor="left" className="custom-scrollbar">
            <Divider />
            <List>
                {componentLinks.map((item, index) => (
                    <NavItem id={index} item={item} key={'nav' + index} />
                ))}
                {formLinks.map((item, index) => (
                    <NavItem id={index} item={item} key={'nav' + index} />
                ))}
            </List>
        </DrawerWrapper>
    );

    return <div>{content}</div>;
};

export default withTheme(Sidebar);

const DrawerWrapper = styled(Drawer)`
    flex-shrink: 0;
    .MuiDrawer-paper {
        // background-color: ${({ theme }) => theme?.content?.colors.headerColor};
        // background-color: #78bbf5;
        background-image: linear-gradient(to bottom, #1292B5, #68C4D6);
        color: ${({ theme }) => theme?.content?.colors.textColor};
        width: ${SIDEBAR_WIDTH}px;
        top: ${HEADER_HEIGHT}px;
        bottom: ${FOOTER_HEIGHT}px;
        height: auto;
    }
    .MuiDrawer-paper::-webkit-scrollbar {
        width: 6px;
        display: block;
    }
    .MuiDrawer-paper::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme?.content?.colors.textColor};
    }
`;
