import React from 'react';
import CaseIcon from 'assets/icons/CaseIcon';
import OpenTicketIcon from 'assets/icons/CaseIcon';
import AddTicketIcon from 'assets/icons/AddTicketIcon';
import ViewTicketIcon from 'assets/icons/ViewTicketIcon';
import FormIcon from 'assets/icons/FormIcon';


const Icon = ({ url, label }) => {
    switch (true) {
        case url === 'caseInbox':
            return <CaseIcon />;
        case !!(+url):
            return (label === "Add Ticket" ? <AddTicketIcon /> : (label === "View Tickets" ? <ViewTicketIcon /> : <FormIcon />))
        default:
            return <OpenTicketIcon />;
    }
};

export default Icon;
