import { createTheme } from '@material-ui/core';

export const ADMIN_ROLE = 'TSF-ADMIN';
export const ADMIN = 'Admin';
export const MANAGER = 'manager';
export const ANALYST = 'analyst';

//storage keys
export const REACT_TOKEN = 'react-token'
export const TOKEN = 'token'
export const CURRENT_USER = 'currentUser'
export const EMAIL = 'email'
export const USER_ROLES = 'user-roles'

//KEYCLOAK events

export const AUTH_SUCCESS = 'onAuthSuccess'
export const TOKEN_EXPIRED = 'onTokenExpired'
export const AUTH_LOGOUT = 'onAuthLogout'


// Height and Width
export const HEADER_HEIGHT = 50;
export const FOOTER_HEIGHT = 30;
export const SIDEBAR_WIDTH = 60;

// realm
export const REALM = 'realm';

//network
export const DELETE = 'delete';
export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';

//redux
export const SET_SPINNER = 'SET_SPINNER';
export const CLEAR_STORE = 'clearStore';
export const SHOW_FLASH_MESSAGE = 'SHOW_FLASH_MESSAGE';
export const CLEAN_ALERTS = 'CLEAN_ALERTS';
export const UPDATE_THEMES = 'UPDATE_THEMES';

//styles
export const INPUT_TEXT_MIN_WIDTH = 300;

//initial material theme

export const INITIAL_THEME = createTheme({
    palette: {
        type: 'dark',
        primary: {
            // main: colors.indigo[500],
            main: '#201e45',
            light: '#95CBF7',
        },
        secondary: {
            // main: colors.indigo[500]
            main: '#000',
        },
        error: {
            main: '#f17b7b',
        },
    },
});



// SXP
export const SXP_TITLE = "Welcome to TechSophy Ticketing System"
export const SXP_SUBTITLE = "Service eXperience Platform"
export const SXP_DEFAULT_MSG = "Hi, Welcome to TechSophy Ticketing System"
export const VERSION_ID = "WORKING"

