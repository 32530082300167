import { SAVE_USER_THEME, FORMS, MENU } from '../constants/endpoints'
import { request } from '../services/request';
import { FormProps } from './FormTypes';

interface Id {
    id: string;
}
interface UserTheme extends Id {
    themeId: string;
    userId: string;
    profilePicture: string;
}
interface ResponseProps {
    success?: boolean;
    data?: any;
    message?: string;
}

export const GET_USER_THEME_ENDPOINT = `${process.env.REACT_APP_API_GATEWAY_URL}${SAVE_USER_THEME}`;
export const RUNTIME_FORM_ENDPOINT = `${process.env.REACT_APP_API_GATEWAY_URL}${FORMS}`;
export const GET_MENU_ENDPOINT = `${process.env.REACT_APP_API_GATEWAY_URL}${MENU}`;

export const getMenu = async () => {
    const res: ResponseProps = (await request.get(GET_MENU_ENDPOINT)) as ResponseProps;
    if (res.success) {
        const data = res.data;
        return data;
    }
    return {};
};

export const getUserTheme = async (): Promise<{ success: boolean; message?: string; data?: UserTheme }> => {
    const r: ResponseProps = (await request.get(`${GET_USER_THEME_ENDPOINT}`)) as ResponseProps;
    if (r.success) {
        const data: UserTheme = r.data as UserTheme;
        return { success: true, message: r.message, data: data };
    }
    return { success: false, message: 'Unable to fetch theme' };
};

export const getFormById = async (
    id: string
): Promise<{ success: boolean; data?: FormProps; message?: string }> => {
    const r: ResponseProps = (await request.get(`${RUNTIME_FORM_ENDPOINT}/${id}`)) as ResponseProps;

    if (r.success) {
        const form = r.data as FormProps;
        return { success: r.success, data: form, message: r.message };
    }

    return { success: false };
};

